import React from 'react'

const Jurnal = ({node}) => {
  const { jurnal, volume, page, date } = node;
  return (
    <div className="h4">
      <span className="jurnal">{jurnal}</span>
      {volume && <span className="mx-1">{volume}</span>}
      {page && <span className="mx-1">{page}</span>}
      <JurnalYear date={date} />
    </div>
    )
}


const JurnalYear = ({date}) => {
  const jurnalDate = new Date(date);
  return (
    <span className="mx-1">{jurnalDate.getFullYear()}</span>
  )
}

export default Jurnal
