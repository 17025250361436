import React from "react"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Jurnal from "../components/jurnal";
import CoAuthor from "../components/co-authors";
import SEO from "../components/seo"
import { Row, Col } from "react-bootstrap";


export default function PaperDetail({ data }) {
  const post = data.markdownRemark

  function getFileName(fileName) {
    const f = String(fileName);

    const filePaths = f.split('/');

    return filePaths[filePaths.length - 1];
  }

  return (
    <Layout>
      <SEO title={post.frontmatter.title} description={post.excerpt} />
      <Row className="mb-3" md={12}>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
                <Link to="/research">Research</Link>
            </Breadcrumb.Item>            
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <section id="paper-detail">
            <div>            
              {
                post.frontmatter.lecturefiles !== null ?
                  <a href={'/images/uploads/' + getFileName(post.frontmatter.lecturefiles)} target="__blank" >
                    <h4 className="paper-title">{post.frontmatter.title}</h4>
                  </a>
                  :
                  <h4 className="paper-title">{post.frontmatter.title}</h4>
              }            
              <p>
                {
                  post.frontmatter.jurnal && <Jurnal node={post.frontmatter} ></Jurnal>
                }
                <a target="blank" href={post.frontmatter.doi}>{post.frontmatter.doi}</a>
              </p>
            </div>
            <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
          </section>
        </Col>
        <Col md={2} >          
            {
              post.frontmatter.coauthor !== null && <CoAuthor node={post.frontmatter.coauthor}></CoAuthor>
            }          
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        volume
        title
        coauthor
        date
        doi
        jurnal
        page
        submitted
        published
        lecturefiles
      }
      excerpt
    }
  }
`