import React from 'react'
import { Card, ListGroupItem, ListGroup } from "react-bootstrap";

const CoAuthors = ({ node }) => {
    return (
        <Card style={{ width: '18rem' }} border='light'>
            <Card.Body>
                <Card.Title>
                    Coauthors
                        </Card.Title>
                <ListGroup variant='flush'>
                    {node.map((name, index) => (
                        <ListGroupItem key={index}>{name}</ListGroupItem>
                    ))
                    }
                </ListGroup>
            </Card.Body>
        </Card>

    )
}

export default CoAuthors
